// Static Import
//Always use static import before dynamic
import React from 'react'
import loadable from '@loadable/component'
import Loader from '../components/Partials/FallBackLoader'
import {
  homePagePreload,
  bikeDetailPreload,
  bikeListPreload,
  franchaisePreload,
  investorsPreload,
  sellBikePreload,
  howItWorksPreload,
  // careerPreload
  contactUsPreload,
  faqPreload,
  privacyPolicyPreload,
  termOfUsePreload,
  termAndConditionPreload,
  storePagePreload,
  contactLessDeliveryPreload,
  contactLessServicePreload,
  sellPageValuationPreload,
  exchangeBikePreLoad
} from '../core/serverPreload'
import { HOME, BIKE_DETAIL, BIKE_LIST, STORE_DETAILS, CONTACT_LESS_DELIVERY, CONTACT_LESS_SERVICE, NEW_SERVICE } from '../core/constants/pageConstatnts'
import { cityRegex, vehicleRegex, makeRegex, modelRegex } from '../core/constants/routeConstants'

const fallback = { fallback: <Loader /> }
// Dynamic Imports
const Home = loadable(() => import('../containers/Home'), fallback)
const Investors = loadable(() => import('../containers/Investors'), fallback)
const Franchise = loadable(() => import('../containers/Franchise'), fallback)
const SellBikes = loadable(() => import('../containers/SellYourBike-v2'), fallback)
const SellBikesV1 = loadable(() => import('../containers/SellYourBike-v2V1'), fallback)
const BikeList = loadable(() => import('../containers/Bike-List'), fallback)
const Bike = loadable(() => import('../containers/Bike'), fallback)
const BikePayment = loadable(() => import('../containers/BikePayment'), fallback)
const Contact = loadable(() => import('../containers/ContactUs'), fallback)
const PrivacyPolicy = loadable(() => import('../containers/PrivacyPolicy'), fallback)
const TermsUse = loadable(() => import('../containers/TermsOfUse'), fallback)
const TermsConditions = loadable(() => import('../containers/TermsAndConditions'), fallback)
const FAQ = loadable(() => import('../containers/FAQ'), fallback)
const HowItWorks = loadable(() => import('../containers/Howitworks'), fallback)
// const Careers = loadable(() => import('../containers/Careers'), fallback)
const PageNotFound = loadable(() => import('../containers/PageNotFound'), fallback)
const InternalServerError = loadable(() => import('../containers/InternerServerError'), fallback)
const ThankYou = loadable(() => import('../containers/ThankYou'), fallback)
const ThankYouFitness = loadable(() => import('../containers/ThankYouFitnessBox'), fallback)
const StoreLocator = loadable(() => import('../containers/StoreLocator'), fallback)
const StoreDetails = loadable(() => import('../containers/StoreDetails'), fallback)
const JEVList = loadable(() => import('../containers/JEVList'), fallback)
const JEVVehiclePDP = loadable(() => import('../containers/JEVVehiclePDP'), fallback)
const ContactLessDelivery = loadable(() => import('../containers/ContactLessDelivery'), fallback)
const ContactLessService = loadable(() => import('../containers/ContactLessService'), fallback)
const ThankYouPage = loadable(() => import('../containers/HtrThankyou'), fallback)
const BuyatHomeThankYou = loadable(() => import('../containers/BuyatHomeThankYou'), fallback)
const ThankYouPageService = loadable(() => import('../containers/ServiceThankYou'), fallback)
const ThankYouPageSellBike = loadable(() => import('../containers/SellBikeThankYou'), fallback)
const DoorStepService = loadable(() => import('../containers/DoorStepService'), fallback)
const BikeValutaion = loadable(() => import('../containers/BikeValuation'), fallback)
const BikeValutaionV1 = loadable(() => import('../containers/BikeValuationV1'), fallback)
const ExchangeBike = loadable(() => import('../containers/ExchangeBike'), fallback)
const ExchangeThankyou = loadable(() => import('../containers/ExchangeThankYou'), fallback)
//const ExchangeDisAgreeThank = loadable(()=> import('../containers/ExchangeThankYouDisAgree'), fallback)
const SellLandingAds = loadable(() => import('../containers/SellPageLandingAds'), fallback)
const SellLandingAdsV1 = loadable(() => import('../containers/SellPageLandingAdsV1'), fallback)
const SellAdsThankYouPage = loadable(() => import('../components/SellPageLanding/ThankYouPage'), fallback)
const BuyLandingAds = loadable(() => import('../containers/BuyLandingPage'), fallback)
const BuyAdsThankYouPage = loadable(() => import('../components/BuyLandingPage/ThankYouPage'), fallback)
const SellThankYouFb = loadable(() => import('../containers/SellBikeThankYouFb'), fallback)
const AdSellPageThankYou = loadable(() => import('../containers/AdSellPageThankYou'), fallback)
const SellLandingGoogleAds = loadable(() => import('../containers/SellGoogleAdsNew'), fallback)
const NewBuyLanding = loadable(() => import('../containers/NewBuyLanding'), fallback)
const NewBuyAdsThankYou = loadable(() => import('../components/NewBuyLandingZest/ThankYouPage'), fallback)
const UpgradeExchangeBike = loadable(() => import('../containers/Exchange2.0Page'), fallback)
// const UpgradeExchangeBikeV1 = loadable(() => import('../containers/Exchange2.0PageV1/index.js'), fallback)
const SellHondaLandingPage = loadable(() => import('../containers/SellHondaLandingPages'), fallback)
const ExchanegAdsThankYou = loadable(() => import('../containers/ExchangeAdsThankYou'), fallback)
const ExchangeThankYouDisAgreeAds = loadable(() => import('../containers/ExchangeAdsDisAgreeThankU'), fallback)
const TechnicalSupportPage = loadable(() => import('../containers/TechnicalHelp'), fallback)
const SellThankYouRTO = loadable(() => import('../containers/SellBikeThankYouRTO'), fallback)
const SellNTPThankYou = loadable(() => import('../containers/SellBikeNTPThankU'), fallback)
const MeetCEOPage = loadable(() => import('../containers/MeetCEO'), fallback)
const TermsOfCredrPartner = loadable(() => import('../containers/TermsOfCredrPartner'), fallback)
const ServiceLandingPage = loadable(() => import('../containers/ServiceLandingPage'), fallback)
const AllDEtailsService = loadable(() => import('../components/ServiceFormMain/component/AllDetails'), fallback)
const NewServiceThankU = loadable(() => import('../components/ServiceThankU'), fallback)
const EnhancedSellFlow = loadable(() => import('../containers/EnhancedSellPage'), fallback)
const EnhancedUserFormDetails = loadable(() => import('../components/EnhancedSell/UserFormDetails'))
const EnhancedThankYou = loadable(() => import('../containers/SellBikeThankYou/component/EnhancedSellThanku'), fallback)
const DoorStepServiceLanding = loadable(() => import('../containers/ServiceLanding2'), fallback)
const CoreServicePayment = loadable(() => import('../containers/CoreServicePayment'), fallback)
const SellNTPYearThankYou = loadable(() => import('../containers/SellBikeNTPYearThankYou'), fallback)
const ServicePageNA = loadable(() => import('../containers/ServicePageNA'))
const NewBikes = loadable(() => import('../containers/NewBike'), fallback)
const AdSellPage = loadable(() => import('../containers/AdSellPage'), fallback)
const AdsDemandLeads = loadable(() => import('../containers/AdsDemandLeads'), fallback)
const AdsThankyouPage = loadable(() => import('../containers/AdsDemandLeads/components/ThankyouPage.js'), fallback)

const StudentAmbassador = loadable(() => import('../containers/StudentAmbassador'), fallback)
const TurboxoQR = loadable(() => import('../containers/TurboxoQR'), fallback)
const InspectionVerification = loadable(() => import('../containers/InspectionVerification'), fallback)
const ThankYouNewBike = loadable(() => import('../components/NewBike-Coupen'), fallback)

const order_id = 1234

export const routes = [
  {
    path: `/all-used-:vehicle(${vehicleRegex})-in-:city(${cityRegex})-:area/:make(${makeRegex})-:model-:variant/:vehicleId`,
    exact: true,
    component: Bike,
    page: BIKE_DETAIL,
    preLoad: (store, url) => bikeDetailPreload(store, url)
  },
  {
    path: `/all-used-:vehicle(${vehicleRegex})-buy-at-homein-:city(${cityRegex})-:area/:make(${makeRegex})-:model(${modelRegex})-:variant/:vehicleId`,
    exact: true,
    component: Bike,
    page: BIKE_DETAIL,
    preLoad: (store, url) => bikeDetailPreload(store, url)
  },
  {
    path: `/all-used-:vehicle(${vehicleRegex})-in-:city(${cityRegex})-:area/:make(${makeRegex})-:model(${modelRegex})/:vehicleId`,
    exact: true,
    component: Bike,
    page: BIKE_DETAIL,
    preLoad: (store, url) => bikeDetailPreload(store, url)
  },
  {
    path: `/all-used-:vehicle(${vehicleRegex})-buy-at-home-in-:city(${cityRegex})-:area/:make(${makeRegex})-:model(${modelRegex})/:vehicleId`,
    exact: true,
    component: Bike,
    page: BIKE_DETAIL,
    preLoad: (store, url) => bikeDetailPreload(store, url)
  },
  {
    path: `/pay`,
    exact: true,
    component: BikePayment,
    page: BIKE_DETAIL,
    preLoad: (store, url) => bikeDetailPreload(store, url)
  },
  {
    path: "/contactless-delivery/thank-you",
    exact: true,
    component: ThankYouPage
  },
  {
    path: "/contactless-delivery/buy-at-home-thank-you",
    exact: true,
    component: BuyatHomeThankYou
  },
  {
    path: "/credr-partner-t&c",
    component: TermsOfCredrPartner,
  },
  {
    path: "/contactless-service/thank-you",
    exact: true,
    component: ThankYouPageService
  },
  {
    path: "/newcontactless-service/thank-you",
    exact: true,
    component: NewServiceThankU
  },
  {
    path: "/all-used-bikes",
    exact: true,
    component: BikeList,
    page: BIKE_LIST,
    preLoad: (store, url) => bikeListPreload(store, url)
  },
  {
    path: "/all-used-bikes-buy-at-home",
    exact: true,
    component: BikeList,
    page: BIKE_LIST,
    preLoad: (store, url) => bikeListPreload(store, url)
  },
  {
    path: "/contactless-delivery",
    exact: true,
    component: ContactLessDelivery,
    page: CONTACT_LESS_DELIVERY,
    preLoad: (store, url) => contactLessDeliveryPreload(store, url)

  },
  {
    path: "/contactless-service",
    exact: true,
    component: ContactLessService,
    page: CONTACT_LESS_SERVICE,
    preLoad: (store, url) => contactLessServicePreload(store, url)
  },
  {
    path: `/all-used-:vehicle(${vehicleRegex})`,
    exact: true,
    component: BikeList,
    page: BIKE_LIST,
    preLoad: (store, url) => bikeListPreload(store, url)
  },
  {
    path: `/all-used-bikes/:make(${makeRegex})-:model(${modelRegex})-:variant`,
    exact: true,
    component: BikeList,
    page: BIKE_LIST,
    preLoad: (store, url) => bikeListPreload(store, url)
  },
  {
    path: `/all-used-bikes/:make(${makeRegex})-:model(${modelRegex})`,
    exact: true,
    component: BikeList,
    page: BIKE_LIST,
    preLoad: (store, url) => bikeListPreload(store, url)
  },
  {
    path: `/all-used-bikes/:make(${makeRegex})`,
    exact: true,
    component: BikeList,
    page: BIKE_LIST,
    preLoad: (store, url) => bikeListPreload(store, url)
  },
  {
    path: `/used-:vehicle(${vehicleRegex})-in-:city(${cityRegex})-:area/:make(${makeRegex})-:model(${modelRegex})-:variant`,
    exact: true,
    component: BikeList,
    page: BIKE_LIST,
    preLoad: (store, url) => bikeListPreload(store, url)
  },
  {
    path: `/used-:vehicle(${vehicleRegex})-in-:city(${cityRegex})-:area/:make(${makeRegex})-:model(${modelRegex})`,
    exact: true,
    component: BikeList,
    page: BIKE_LIST,
    preLoad: (store, url) => bikeListPreload(store, url)
  },
  {
    path: `/used-:vehicle(${vehicleRegex})-in-:city(${cityRegex})-:area/:make(${makeRegex})`,
    exact: true,
    component: BikeList,
    page: BIKE_LIST,
    preLoad: (store, url) => bikeListPreload(store, url)
  },
  {
    path: `/used-:vehicle(${vehicleRegex})-in-:city(${cityRegex})-:area`,
    exact: true,
    component: BikeList,
    page: BIKE_LIST,
    preLoad: (store, url) => bikeListPreload(store, url)
  },
    {
      path: `/used-bikes-in-:city(${cityRegex})`,
      exact: true,
      component: BikeList,
      page: BIKE_LIST,
      preLoad: (store, url) => bikeListPreload(store, url)
    },
    {
    path: `/used-:vehicle(${vehicleRegex})-in-:city(${cityRegex})`,
    exact: true,
    component: BikeList,
    page: BIKE_LIST,
    preLoad: (store, url) => bikeListPreload(store, url)
  },
  {
    path: `/used-:vehicle(${vehicleRegex})-in-:city(${cityRegex})/:make(${makeRegex})-:model(${modelRegex})-:variant`,
    exact: true,
    component: BikeList,
    page: BIKE_LIST,
    preLoad: (store, url) => bikeListPreload(store, url)
  },
  {
    path: `/used-:vehicle(${vehicleRegex})-in-:city(${cityRegex})/:make(${makeRegex})-:model(${modelRegex})`,
    exact: true,
    component: BikeList,
    page: BIKE_LIST,
    preLoad: (store, url) => bikeListPreload(store, url)
  },
  {
    path: `/used-:vehicle(${vehicleRegex})-in-:city(${cityRegex})/:make(${makeRegex})`,
    exact: true,
    component: BikeList,
    page: BIKE_LIST,
    preLoad: (store, url) => bikeListPreload(store, url)
  },
  {
    path: `/all-used-bikes-in-:city(${cityRegex})-:area/:make(${makeRegex})-:model(${modelRegex})-:variant`,
    exact: true,
    component: BikeList,
    page: BIKE_LIST,
    preLoad: (store, url) => bikeListPreload(store, url)
  },
  {
    path: `/all-used-bikes-in-:city(${cityRegex})-:area/:make(${makeRegex})-:model(${modelRegex})`,
    exact: true,
    component: BikeList,
    page: BIKE_LIST,
    preLoad: (store, url) => bikeListPreload(store, url)
  },
  {
    path: `/all-used-bikes-in-:city(${cityRegex})-:area`,
    exact: true,
    component: BikeList,
    page: BIKE_LIST,
    preLoad: (store, url) => bikeListPreload(store, url)
  },
  {
    path: `/all-used-bikes-in-:city(${cityRegex})-:area/:make(${makeRegex})`,
    exact: true,
    component: BikeList,
    page: BIKE_LIST,
    preLoad: (store, url) => bikeListPreload(store, url)
  },
  {
    path: `/all-used-bikes-in-:city(${cityRegex})/:make(${makeRegex})-:model(${modelRegex})-:variant`,
    exact: true,
    component: BikeList,
    page: BIKE_LIST,
    preLoad: (store, url) => bikeListPreload(store, url)
  },
  {
    path: `/all-used-bikes-in-:city(${cityRegex})/:make(${makeRegex})-:model(${modelRegex})`,
    exact: true,
    component: BikeList,
    page: BIKE_LIST,
    preLoad: (store, url) => bikeListPreload(store, url)
  },
  {
    path: `/all-used-bikes-in-:city(${cityRegex})/:make(${makeRegex})`,
    exact: true,
    component: BikeList,
    page: BIKE_LIST,
    preLoad: (store, url) => bikeListPreload(store, url)
  },
  {
    path: `/all-used-bikes-in-:city(${cityRegex})`,
    exact: true,
    component: BikeList,
    page: BIKE_LIST,
    preLoad: (store, url) => bikeListPreload(store, url)
  },
  {
    path: `/all-used-:vehicle(${vehicleRegex})-in-:city(${cityRegex})-:area/:make(${makeRegex})-:model(${modelRegex})-:variant`,
    exact: true,
    component: BikeList,
    page: BIKE_LIST,
    preLoad: (store, url) => bikeListPreload(store, url)
  },
  {
    path: `/all-used-:vehicle(${vehicleRegex})-buy-at-home-in-:city(${cityRegex})-:area/:make(${makeRegex})-:model(${modelRegex})-:variant`,
    exact: true,
    component: BikeList,
    page: BIKE_LIST,
    preLoad: (store, url) => bikeListPreload(store, url)
  },
  {
    path: `/all-used-:vehicle(${vehicleRegex})-in-:city(${cityRegex})-:area/:make(${makeRegex})-:model(${modelRegex})`,
    exact: true,
    component: BikeList,
    page: BIKE_LIST,
    preLoad: (store, url) => bikeListPreload(store, url)
  },
  {
    path: `/all-used-:vehicle(${vehicleRegex})-buy-at-home-in-:city(${cityRegex})-:area/:make(${makeRegex})-:model(${modelRegex})`,
    exact: true,
    component: BikeList,
    page: BIKE_LIST,
    preLoad: (store, url) => bikeListPreload(store, url)
  },
  {
    path: `/all-used-:vehicle(${vehicleRegex})-in-:city(${cityRegex})-:area`,
    exact: true,
    component: BikeList,
    page: BIKE_LIST,
    preLoad: (store, url) => bikeListPreload(store, url)
  },
  {
    path: `/all-used-:vehicle(${vehicleRegex})-buy-at-home-in-:city(${cityRegex})-:area`,
    exact: true,
    component: BikeList,
    page: BIKE_LIST,
    preLoad: (store, url) => bikeListPreload(store, url)
  },
  {
    path: `/all-used-:vehicle(${vehicleRegex})-in-:city(${cityRegex})-:area/:make(${makeRegex})`,
    exact: true,
    component: BikeList,
    page: BIKE_LIST,
    preLoad: (store, url) => bikeListPreload(store, url)
  },
  {
    path: `/all-used-:vehicle(${vehicleRegex})-buy-at-home-in-:city(${cityRegex})-:area/:make(${makeRegex})`,
    exact: true,
    component: BikeList,
    page: BIKE_LIST,
    preLoad: (store, url) => bikeListPreload(store, url)
  },
  {
    path: `/all-used-:vehicle(${vehicleRegex})-in-:city(${cityRegex})/:make(${makeRegex})-:model(${modelRegex})-:variant`,
    exact: true,
    component: BikeList,
    page: BIKE_LIST,
    preLoad: (store, url) => bikeListPreload(store, url)
  },
  {
    path: `/all-used-:vehicle(${vehicleRegex})-buy-at-home-in-:city(${cityRegex})/:make(${makeRegex})-:model(${modelRegex})-:variant`,
    exact: true,
    component: BikeList,
    page: BIKE_LIST,
    preLoad: (store, url) => bikeListPreload(store, url)
  },
  {
    path: `/all-used-:vehicle(${vehicleRegex})-in-:city(${cityRegex})/:make(${makeRegex})-:model(${modelRegex})`,
    exact: true,
    component: BikeList,
    page: BIKE_LIST,
    preLoad: (store, url) => bikeListPreload(store, url)
  },
  {
    path: `/all-used-:vehicle(${vehicleRegex})-buy-at-home-in-:city(${cityRegex})/:make(${makeRegex})-:model(${modelRegex})`,
    exact: true,
    component: BikeList,
    page: BIKE_LIST,
    preLoad: (store, url) => bikeListPreload(store, url)
  },
  {
    path: `/all-used-:vehicle(${vehicleRegex})-in-:city(${cityRegex})/:make(${makeRegex})`,
    exact: true,
    component: BikeList,
    page: BIKE_LIST,
    preLoad: (store, url) => bikeListPreload(store, url)
  },
  {
    path: `/all-used-:vehicle(${vehicleRegex})-buy-at-home-in-:city(${cityRegex})/:make(${makeRegex})`,
    exact: true,
    component: BikeList,
    page: BIKE_LIST,
    preLoad: (store, url) => bikeListPreload(store, url)
  },
  {
    path: `/all-used-:vehicle(${vehicleRegex})-in-:city(${cityRegex})`,
    exact: true,
    component: BikeList,
    page: BIKE_LIST,
    preLoad: (store, url) => bikeListPreload(store, url)
  },
  {
    path: `/all-used-:vehicle(${vehicleRegex})-buy-at-home-in-:city(${cityRegex})`,
    exact: true,
    component: BikeList,
    page: BIKE_LIST,
    preLoad: (store, url) => bikeListPreload(store, url)
  },
  {
    path: `/all-used-:vehicle(${vehicleRegex})/:make(${makeRegex})-:model(${modelRegex})-:variant`,
    exact: true,
    component: BikeList,
    page: BIKE_LIST,
    preLoad: (store, url) => bikeListPreload(store, url)
  },
  {
    path: `/all-used-:vehicle(${vehicleRegex})-buy-at-home/:make(${makeRegex})-:model(${modelRegex})-:variant`,
    exact: true,
    component: BikeList,
    page: BIKE_LIST,
    preLoad: (store, url) => bikeListPreload(store, url)
  },
  {
    path: `/all-used-:vehicle(${vehicleRegex})/:make(${makeRegex})-:model(${modelRegex})`,
    exact: true,
    component: BikeList,
    page: BIKE_LIST,
    preLoad: (store, url) => bikeListPreload(store, url)
  },
  {
    path: `/all-used-:vehicle(${vehicleRegex})-buy-at-home/:make(${makeRegex})-:model(${modelRegex})`,
    exact: true,
    component: BikeList,
    page: BIKE_LIST,
    preLoad: (store, url) => bikeListPreload(store, url)
  },
  {
    path: `/all-used-:vehicle(${vehicleRegex})/:make(${makeRegex})`,
    exact: true,
    component: BikeList,
    page: BIKE_LIST,
    preLoad: (store, url) => bikeListPreload(store, url)
  },
  {
    path: `/all-used-:vehicle(${vehicleRegex})-buy-at-home/:make(${makeRegex})`,
    exact: true,
    component: BikeList,
    page: BIKE_LIST,
    preLoad: (store, url) => bikeListPreload(store, url)
  },
  {
    path: `/used-:vehicle(${vehicleRegex})/:make(${makeRegex})-:model(${modelRegex})-:variant`,
    exact: true,
    component: BikeList,
    page: BIKE_LIST,
    preLoad: (store, url) => bikeListPreload(store, url)
  },
  {
    path: `/used-:vehicle(${vehicleRegex})/:make(${makeRegex})-:model(${modelRegex})`,
    exact: true,
    component: BikeList,
    page: BIKE_LIST,
    preLoad: (store, url) => bikeListPreload(store, url)
  },
  {
    path: `/used-:vehicle(${vehicleRegex})/:make(${makeRegex})`,
    exact: true,
    component: BikeList,
    page: BIKE_LIST,
    preLoad: (store, url) => bikeListPreload(store, url)
  },
  {
    path: "/how-it-works",
    exact: true,
    component: HowItWorks,
    preLoad: (store, url) => howItWorksPreload(store, url)
  },
  {
    path: "/sellbikes/thank-you",
    exact: true,
    component: ThankYouPageSellBike
  },
  {
    path: "/enhanced-sellbikes/thank-you",
    exact: true,
    component: EnhancedThankYou
  },
  {
    path: "/sellbikes/thank-you-rto",
    exact: true,
    component: SellThankYouRTO
  },
  {
    path: "/sellbikes-ntp/thank-you",
    exact: true,
    component: SellNTPThankYou
  },
  {
    path: "/sell-landing-page-for-ads/thank-you",
    exact: true,
    component: SellAdsThankYouPage
  },
  {
    path: `/buy-landing-page-for-ads/thank-you`,
    exact: true,
    component: BuyAdsThankYouPage
  },
  {
    path: `/buy-landing-page-for-zest/thank-you`,
    exact: true,
    component: NewBuyAdsThankYou
  },
  {
    path: "/franchise/thank-you",
    exact: true,
    component: ThankYou
  },
  {
    path: "/buy/thank-you",
    exact: true,
    component: ThankYou
  },
  {
    path: "/buy/thank-you-fitness",
    exact: true,
    component: ThankYouFitness
  },
  {
    path: "/sell-thank-you-ntp",
    exact: true,
    component: SellNTPYearThankYou
  },
  {
    path: "/sell-your-bike-buy-new-bike/thank-you",
    exact: true,
    component: ExchangeThankyou
  },
  {
    path: "/sell-your-bike-buy-new-bike/fb/thank-you",
    exact: true,
    component: ExchanegAdsThankYou
  },
  {
    path: '/sell-your-bike-buy-new-bike/fb/thankYouDisAgree',
    exact: true,
    component: ExchangeThankYouDisAgreeAds
  },
  {
    path: `/new-bikes-thank-you`,
    exact: true,
    component: ThankYouNewBike
  },
  {
    path: '/sell-landing-page-for-ads-bangalore',
    exact: true,
    component: SellLandingAds
  },
  {
    path: '/sell-landing-page-for-ads-delhi',
    exact: true,
    component: SellLandingAds
  },
  {
    path: '/sell-landing-page-for-ads-tumkur',
    exact: true,
    component: SellLandingAds
  },
  {
    path: '/sell-landing-page-for-ads-nashik',
    exact: true,
    component: SellLandingAds
  },
  {
    path: '/v1/sell-landing-page-for-ads-bangalore',
    exact: true,
    component: SellLandingAdsV1
  },
  {
    path: '/v1/sell-landing-page-for-ads-delhi',
    exact: true,
    component: SellLandingAdsV1
  },
  {
    path: '/v1/sell-landing-page-for-ads-tumkur',
    exact: true,
    component: SellLandingAdsV1
  },
  {
    path: '/v1/sell-landing-page-for-ads-nashik',
    exact: true,
    component: SellLandingAdsV1
  },
  {
    path: '/sell-landing-page-for-ads',
    exact: true,
    component: SellLandingAds
  },
  {
    path: '/buy-landing-page',
    exact: true,
    component: BuyLandingAds
  },
  {
    path: '/buy-landing-page-for-zest-bikes',
    exact: true,
    component: NewBuyLanding
  },
  {
    path: '/sell-landing-page-for-ads-pune',
    exact: true,
    component: SellLandingAds
  },
  {
    path: '/sell-landing-page-for-ads-mumbai',
    exact: true,
    component: SellLandingAds
  },
  {
    path: '/sell-landing-page-for-ads-ahmedabad',
    exact: true,
    component: SellLandingAds
  },
  {
    path: '/v1/sell-landing-page-for-ads-pune',
    exact: true,
    component: SellLandingAdsV1
  },
  {
    path: '/v1/sell-landing-page-for-ads-mumbai',
    exact: true,
    component: SellLandingAdsV1
  },
  {
    path: '/v1/sell-landing-page-for-ads-ahmedabad',
    exact: true,
    component: SellLandingAdsV1
  },
  {
    path: '/sell-landing-page-for-google-ads',
    exact: true,
    component: SellLandingGoogleAds
  },
  {
    path: '/struggling-to-sell-your-Honda-Activa-or-Honda-CD-Deluxe',
    exact: true,
    component: SellHondaLandingPage
  },
  {
    path: "/faq",
    exact: true,
    component: FAQ,
    preLoad: (store, url) => faqPreload(store, url)
  },
  // {
  //   path: "/careers",
  //   exact: true,
  //   component: Careers,
  //   preLoad: (store, url) => careerPreload(store, url)
  // },
  {
    path: "/terms-of-use",
    exact: true,
    component: TermsUse,
    preLoad: (store, url) => termOfUsePreload(store, url)
  },
  {
    path: "/oem-t&c",
    exact: true,
    component: TermsConditions,
    preLoad: (store, url) => termAndConditionPreload(store, url)
  },
  {
    path: "/privacy-policy",
    exact: true,
    component: PrivacyPolicy,
    preLoad: (store, url) => privacyPolicyPreload(store, url)
  },
  {
    path: "/contact-us",
    exact: true,
    component: Contact,
    preLoad: (store, url) => contactUsPreload(store, url)
  },
  {
    path: `/used-:vehicle(${vehicleRegex})`,
    exact: true,
    component: BikeList,
    page: BIKE_LIST,
    preLoad: (store, url) => bikeListPreload(store, url)
  },
  {
    path: "/investors",
    exact: true,
    component: Investors,
    preLoad: (store, url) => investorsPreload(store, url)
  },
  {
    path: "/franchise",
    exact: true,
    component: Franchise,
    preLoad: (store, url) => franchaisePreload(store, url)
  },
  {
    path: "/showroom-locator",
    exact: true,
    component: StoreLocator
  },
  {
    path: "/sellbikes",
    exact: true,
    // component: SellBikes,
    component: EnhancedSellFlow,
    preLoad: (store, url) => sellBikePreload(store, url)
  },
  {
    path: "/sellbikes-old-flow",
    exact: true,
    component: SellBikes,
    // component: EnhancedSellFlow,
    preLoad: (store, url) => sellBikePreload(store, url)
  },
  {
    path: "/sellbikes/fb/thank-you",
    exact: true,
    component: SellThankYouFb,
    preLoad: (store, url) => sellBikePreload(store, url)
  },
  {
    path: "/ad-sell-page/thank-you",
    exact: true,
    component: AdSellPageThankYou,
    preLoad: (store, url) => sellBikePreload(store, url)
  },
  {
    path: "/sell_bike_fb_bangalore",
    exact: true,
    component: SellBikes,
    preLoad: (store, url) => sellBikePreload(store, url)
  },
  {
    path: "/v1/sell_bike_fb_bangalore",
    exact: true,
    component: SellBikesV1,
    preLoad: (store, url) => sellBikePreload(store, url)
  },
  {
    path: "/sell_bike_fb_delhi",
    exact: true,
    component: SellBikes,
    preLoad: (store, url) => sellBikePreload(store, url)
  },
  {
    path: "/v1/sell_bike_fb_delhi",
    exact: true,
    component: SellBikesV1,
    preLoad: (store, url) => sellBikePreload(store, url)
  },
  {
    path: "/sell_bike_fb_ahmedabad",
    exact: true,
    component: SellBikes,
    preLoad: (store, url) => sellBikePreload(store, url)
  },
  {
    path: "/v1/sell_bike_fb_ahmedabad",
    exact: true,
    component: SellBikesV1,
    preLoad: (store, url) => sellBikePreload(store, url)
  },
  {
    path: '/sell_bike_fb_jaipur',
    exact: true,
    component: SellBikes,
    preLoad: (store, url) => sellBikePreload(store, url)
  },
  {
    path: '/v1/sell_bike_fb_jaipur',
    exact: true,
    component: SellBikesV1,
    preLoad: (store, url) => sellBikePreload(store, url)
  },
  {
    path: "/sell_bike_fb_pune",
    exact: true,
    component: SellBikes,
    preLoad: (store, url) => sellBikePreload(store, url)
  },
  {
    path: "/v1/sell_bike_fb_pune",
    exact: true,
    component: SellBikesV1,
    preLoad: (store, url) => sellBikePreload(store, url)
  },
  {
    path: "/sell_bike_fb_mumbai",
    exact: true,
    component: SellBikes,
    preLoad: (store, url) => sellBikePreload(store, url)
  },
  {
    path: "/v1/sell_bike_fb_mumbai",
    exact: true,
    component: SellBikesV1,
    preLoad: (store, url) => sellBikePreload(store, url)
  },
  {
    path: "/sell_bike_fb_nashik",
    exact: true,
    component: SellBikes,
    preLoad: (store, url) => sellBikePreload(store, url)
  },
  {
    path: "/v1/sell_bike_fb_nashik",
    exact: true,
    component: SellBikesV1,
    preLoad: (store, url) => sellBikePreload(store, url)
  },
  {
    path: '/sellbikes/banglore/yamaha/',
    exact: true,
    component: SellBikes,
    preLoad: (store, url) => sellBikePreload(store, url)
  },
  {
    path: '/sellbikes-enhanced',
    exact: true,
    component: EnhancedSellFlow,
    preLoad: (store, url) => sellBikePreload(store, url)
  },
  {
    path: `/sellbikes-in-:city(${cityRegex})`,
    exact: true,
    component: SellBikes,
    preLoad: (store, url) => sellBikePreload(store, url)
  },
  {
    path: "/sell_bike_fb_hyderabad",
    exact: true,
    component: SellBikes,
    preLoad: (store, url) => sellBikePreload(store, url)
  },
  {
    path: "/sell_bike_fb_pune",
    exact: true,
    component: SellBikes,
    preLoad: (store, url) => sellBikePreload(store, url)
  },
  // {
  //   path: "/sell_bike_fb_hyderabad",
  //   exact: true,
  //   component: SellBikes,
  //   preLoad: (store, url) => sellBikePreload(store, url)
  // },
  // {
  //   path: "/sell_bike_fb_pune",
  //   exact: true,
  //   component: SellBikes,
  //   preLoad: (store, url) => sellBikePreload(store, url)
  // },
  {
    path: "/door-step-service",
    exact: true,
    component: ServiceLandingPage
  },
  // {
  //   path: "/door-step-service",
  //   exact: true,
  //   component: ServicePageNA
  // },
  // {
  //   path: "/door-step-service-landing",
  //   exact: true,
  //   component: ServicePageNA
  // },
  {
    path: "/s",
    exact: true,
    component: CoreServicePayment
  },
  // {
  //   path: "/door-step-service/care", // temoprariley removed - 28-12-2022
  //   exact: true,
  //   component: ServiceLandingPage
  // },
  {
    path: "/enhanced-sell-user-details",
    exact: true,
    component: EnhancedUserFormDetails
  },
  {
    path: "/new-bikes",
    exact: true,
    component: NewBikes
  },
  {
    path: "/ad-demand-leads",
    exact: true,
    component: AdsDemandLeads
  },
  {
    path: "/ad-sell-page",
    exact: true,
    component: AdSellPage
  },
  {
    path: "/ad-thankyou",
    exact: true,
    component: AdsThankyouPage
  },
  {
    path: "/student-ambassador",
    exact: true,
    component: StudentAmbassador
  },
  {
    path: "/v",
    exact: true,
    component: TurboxoQR
  },
  {
    path: "/ins",
    exact: true,
    component: InspectionVerification
  },
  // {
  //   path: "/service-ads/",
  //   exact: true,
  //   component: ServiceLandingPage,
  // },
  {
    path: "/service-userDetails",
    exact: true,
    component: AllDEtailsService
  },
  {
    path: '/technical-help',
    exact: true,
    component: TechnicalSupportPage
  },
  {
    path: "/sell-your-bike-buy-new-bike",
    exact: true,
    component: UpgradeExchangeBike,
    preLoad: (store, url) => exchangeBikePreLoad(store, url)
  },
  {
    path: "/sell-your-bike-buy-new-bike/banglore/yamaha",
    exact: true,
    component: UpgradeExchangeBike,
    preLoad: (store, url) => exchangeBikePreLoad(store, url)
  },
  {
    path: "/sell-your-bike-buy-new-bike/delhi/yamaha",
    exact: true,
    component: UpgradeExchangeBike,
    preLoad: (store, url) => exchangeBikePreLoad(store, url)
  },
  {
    path: "/sell-your-bike-buy-new-bike/pune",
    exact: true,
    component: UpgradeExchangeBike,
    preLoad: (store, url) => exchangeBikePreLoad(store, url)
  },
  {
    path: "/sell-your-bike-buy-new-bike/jaipur",
    exact: true,
    component: UpgradeExchangeBike,
    preLoad: (store, url) => exchangeBikePreLoad(store, url)
  },
  {
    path: '/sell-your-bike-buy-new-bike/ahmedabad',
    exact: true,
    component: UpgradeExchangeBike,
    preLoad: (store, url) => exchangeBikePreLoad(store, url)
  },
  {
    path: '/sell-your-bike-buy-new-bike/bhilwara',
    exact: true,
    component: UpgradeExchangeBike,
    preLoad: (store, url) => exchangeBikePreLoad(store, url)
  },
  // {
  //   path: '/sell-your-bike-buy-new-bike-upgrade',
  //   exact: true,
  //   component: UpgradeExchangeBike,
  //   preLoad: (store, url) => exchangeBikePreLoad(store, url)
  // },
  {
    path: "/sell-your-bike-buy-new-bike/pune",
    exact: true,
    component: UpgradeExchangeBike,
    preLoad: (store, url) => exchangeBikePreLoad(store, url)
  },
  {
    path: "/sell-your-bike-buy-new-bike/jaipur",
    exact: true,
    component: UpgradeExchangeBike,
    preLoad: (store, url) => exchangeBikePreLoad(store, url)
  },
  {
    path: '/sell-your-bike-buy-new-bike/ahmedabad',
    exact: true,
    component: UpgradeExchangeBike,
    preLoad: (store, url) => exchangeBikePreLoad(store, url)
  },
  {
    path: '/sell-your-bike-buy-new-bike/bhilwara',
    exact: true,
    component: UpgradeExchangeBike,
    preLoad: (store, url) => exchangeBikePreLoad(store, url)
  },
  {
    path: `/sell-your-bike-buy-new-bike-in-:city(${cityRegex})`,
    exact: true,
    component: ExchangeBike,
    preLoad: (store, url) => exchangeBikePreLoad(store, url)
  },
  {
    path: "/meet-our-ceo",
    exact: true,
    component: MeetCEOPage
  },
  {
    path: "/sellbikes/verloop",
    exact: true,
    component: SellBikes,
    preLoad: (store, url) => sellBikePreload(store, url)
  },
  {
    path: "/sellbikes/blive",
    exact: true,
    component: SellBikes,
    preLoad: (store, url) => sellBikePreload(store, url)
  },
  {
    path: "/bike-valutaion",
    exact: true,
    component: BikeValutaion,
    preLoad: (store, url) => sellPageValuationPreload(store, url)
  },
  {
    path: "/bike-valutaion-blive",
    exact: true,
    component: BikeValutaion,
    preLoad: (store, url) => sellPageValuationPreload(store, url)
  },
  {
    path: "/v1/bike-valutaion",
    exact: true,
    component: BikeValutaionV1,
    preLoad: (store, url) => sellPageValuationPreload(store, url)
  },
  {
    path: "/v1/bike-valutaion-blive",
    exact: true,
    component: BikeValutaionV1,
    preLoad: (store, url) => sellPageValuationPreload(store, url)
  },
  {
    path: `/:cityName(${cityRegex})`,
    component: Home,
    page: HOME,
    preLoad: (store, url) => homePagePreload(store, url)
  },
  {
    path: `/used-bikes-showroom/:cityName(${cityRegex})-:showroomName`,
    component: StoreDetails,
    page: STORE_DETAILS,
    preLoad: (store, url) => storePagePreload(store, url)
  },
  {
    path: `/electric-vehicles/:id`,
    component: JEVVehiclePDP
  },
  {
    path: `/electric-vehicles`,
    component: JEVList
  },
  {
    path: "/",
    exact: true,
    component: Home,
    page: HOME,
    preLoad: (store, url) => homePagePreload(store, url)
  },
  {
    path: "/404",
    exact: true,
    component: PageNotFound
  },
  {
    path: "/500",
    exact: true,
    component: InternalServerError
  },
  {
    path: "*",
    component: PageNotFound
  }
]